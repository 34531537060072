import { setLocale, addMethod, string, ref } from "yup";
import "dayjs/locale/ja";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import { PhoneNumberUtil, PhoneNumberFormat } from "google-libphonenumber";

dayjs.locale("ja");
dayjs.extend(localizedFormat);
dayjs.extend(duration);
dayjs.extend(relativeTime);

setLocale({
  mixed: {
    required: "${label}は必須です",
  },
  string: {
    min: "${label}は${min}文字以上入力してください",
    max: "${label}は${max}文字以内で入力してください",
    email: "有効なメールアドレスを入力してください",
    url: "有効なURLを入力してください",
  },
  number: {
    min: "${label}は${min}以上を入力してください",
    max: "${label}は${max}以下を入力してください",
  },
});

const phoneNumberUtil = new PhoneNumberUtil();

addMethod(string, "hiragana", function hiragana() {
  return this.matches(/^[ぁ-んー]+$/, {
    message: "ひらがなで入力してください",
    excludeEmptyString: true,
  });
});

addMethod(string, "password", function password() {
  return this.min(8)
    .matches(/(?=.*\d).*/, "半角数字を最低1文字含んでください")
    .matches(/(?=.*[a-zA-z]).*/, "半角英字を最低1文字含んでください");
});

addMethod(string, "confirmation", function confirmation({ key, message }) {
  return this.oneOf([ref(key), undefined], message);
});

addMethod(string, "nickname", function nickname() {
  return this.trim()
    .max(15)
    .matches(
      /^[\p{sc=Hiragana}\p{sc=Katakana}\p{sc=Han}A-Za-zＡ-Ｚａ-ｚ0-9０-９ー ]+$/u,
      {
        message:
          "ひらがな、カタカナ、漢字、半角全角数字、半角全角アルファベット以外は使用できません",
        excludeEmptyString: true,
      },
    );
});

addMethod(string, "postTitle", function nickname() {
  return this.trim().max(30);
});

addMethod(string, "phoneNumber", function nickname() {
  return this.transform((value) => {
    if (phoneNumberUtil.isPossibleNumberString(value!, "JP")) {
      const phoneNumber = phoneNumberUtil.parseAndKeepRawInput(value!, "JP");
      return phoneNumberUtil.format(phoneNumber, PhoneNumberFormat.NATIONAL);
    } else {
      return value;
    }
  }).test("is-phone-number", "電話番号は不正です", (value) => {
    if (value === "") return true;
    if (phoneNumberUtil.isPossibleNumberString(value!, "JP")) {
      const phoneNumber = phoneNumberUtil.parseAndKeepRawInput(value!, "JP");
      return phoneNumberUtil.isValidNumberForRegion(phoneNumber, "JP");
    } else {
      return false;
    }
  });
});

declare module "yup" {
  interface StringSchema {
    phoneNumber(): this;
    hiragana(): this;
  }
}
