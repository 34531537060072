import { useSnackbar } from "notistack";
import useBaseRequest from "../../../shared/lib/useBaseRequest";
import { useCallback } from "react";

const useRequest = () => {
  const { enqueueSnackbar } = useSnackbar();
  const onUnexpectedError = useCallback(
    () => enqueueSnackbar("エラーが発生しました", { variant: "error" }),
    [enqueueSnackbar],
  );

  const onForbiddenError = useCallback(
    async (message: string) => {
      enqueueSnackbar(message, { variant: "error" });
    },
    [enqueueSnackbar],
  );

  return useBaseRequest({ onUnexpectedError, onForbiddenError });
};

export default useRequest;
