import * as Sentry from "@sentry/react";

if (process.env.SENTRY_ENVIRONMENT != "") {
  Sentry.init({
    dsn: "https://57dee79e77bc9d68a9f02fc2bd7bea54@o1181286.ingest.us.sentry.io/4506890131931136",
    integrations: [Sentry.replayIntegration()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost"],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: process.env.SENTRY_ENVIRONMENT
  });
}
