import React from "react";
import { Helmet } from "react-helmet";

const Fallback = () => {
  return (
    <main>
      <Helmet>
        <style type="text/css">{`
      * {
        margin: 0;
      }

      body {
        font-family:
          IBM Plex Sans JP,
          sans-serif;
        color: #212529;
      }

      main {
        height: 100dvh;
        background-color: #d9eee9;
        display: flex;
        justify-content: center;
        background-image: url("/bg-city-single.svg");
        background-position: bottom;
        background-repeat: repeat-x;
        background-size: contain;
      }

      .card {
        background-color: white;
        width: 100%;
        max-width: 64rem;
        height: fit-content;
        margin: 1.5rem 1rem 0;
        padding: 3.5rem 1.25rem;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .logo {
        width: 8rem;
      }

      .title {
        margin-top: 1.5rem;
        font-weight: 700;
        font-size: 1rem;
        line-height: 120%;
        margin-bottom: 0;
      }

      .subtitle {
        margin-top: 0.5rem;
        font-weight: 700;
        line-height: 120%;
        color: #99a9b0;
      }

      .message {
        margin-top: 3rem;
        max-width: 24.5rem;
        font-size: 14px;
        text-align: center;
      }

      .home-link {
        margin-top: 1.5rem;
        color: #1a827b;
        text-align: center;
        font-size: 14px;
        text-decoration-line: underline;
      }

      @media screen and (min-width: 48rem) {
        .title {
          margin-top: 3rem;
          font-size: 2rem;
        }
      }

      @media screen and (min-width: 62rem) {
        main {
          background-image: url("/bg-city-dubble.svg");
        }

        .card {
          margin-top: 5rem;
        }

        .loco {
          width: 12rem;
        }
      }

      @media screen and (min-width: 96rem) {
        main {
          background-image: url("/bg-city-triple.svg");
        }
      }
        `}</style>
      </Helmet>
      <div className="card">
        <img src="/logo.svg" className="logo" />
        <h1 className="title">エラーが発生しました</h1>
        <div className="subtitle">500 Internal Server Error</div>

        <p className="message">
          一時的にアクセスができない状態です。
          時間を置いて再度お試しください。
        </p>

        <a className="home-link" href="/">
          A-Loop ホームへ戻る
        </a>
      </div>
    </main>
  );
};

export default Fallback;
