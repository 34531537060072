import { useCallback } from "react";

const useBaseRequest = ({
  onUnexpectedError,
  onForbiddenError,
}: {
  onUnexpectedError: (res?: Response) => void;
  onForbiddenError?: (message: string) => void;
}) => {
  const request = useCallback(
    async (
      url: string,
      method: string,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      data?: Record<string, any> | FormData,
    ) => {
      const headers: { Accept: string; "Content-Type"?: string } = {
        Accept: "application/json",
      };

      if (!(data instanceof FormData)) {
        headers["Content-Type"] = "application/json";
      }

      const option: RequestInit = {
        method,
        headers,
        credentials: "same-origin",
      };

      let fetchUrl = url;

      if (data && !(data instanceof FormData) && method === "GET") {
        fetchUrl += `?${new URLSearchParams(data)}`;
      } else if (data) {
        option.body = data instanceof FormData ? data : JSON.stringify(data);
      }

      let res = null;

      try {
        res = await fetch(fetchUrl, option);
      } catch (e) {
        onUnexpectedError();
        throw e;
      }

      if ((500 <= res.status && res.status <= 599) || res.status === 422) {
        onUnexpectedError(res);
      }

      if (res.status === 403 && onForbiddenError) {
        try {
          const resJson = await res.json();
          if (resJson.message) {
            onForbiddenError(resJson.message);
          }
        } catch {
          // 403でJSONが返ってこない場合は期待していない動作が行われているので特に何もしないくて問題ない
        }
      }

      return res;
    },
    [onUnexpectedError, onForbiddenError],
  );

  return request;
};

export default useBaseRequest;
